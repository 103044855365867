import * as React from "react"
const Payment = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        width={800}
        height={800}
        viewBox="0 0 120 120"
        {...props}
    >
        <style>
            {
                ".st0{fill:#383e56}.st1{fill:#acafbf}.st2{opacity:.15;enable-background:new}"
            }
        </style>
        <path
            d="M103.9 76.6 34.6 91.7c-2.6.6-5.2-1.1-5.7-3.7l-9.7-44c-.6-2.6 1.1-5.2 3.7-5.7l69.3-15.1c2.6-.6 5.2 1.1 5.7 3.7l9.6 44.1c.6 2.5-1 5.1-3.6 5.6zM28.8 88l-9.6-44"
            className="st0"
        />
        <path
            d="M20.347 48.983 99.101 31.83l1.958 8.99-78.754 17.151z"
            className="st1"
        />
        <path
            d="M105.8 74.4 36.5 89.5c-2.6.6-5.2-1.1-5.7-3.7l-9.6-44.1c-.2-1-.1-2.1.3-3-1.7 1-2.7 3.1-2.3 5.1L28.8 88c.6 2.6 3.1 4.2 5.7 3.7l69.3-15.1c1.6-.3 2.8-1.4 3.4-2.8-.4.3-.9.5-1.4.6z"
            className="st2"
        />
        <path
            d="M95.7 50.4v45.1c0 1.1-.4 2.2-1.1 3-.8.7-1.9 1.1-3 1.1h-71c-2.7 0-4.8-2.2-4.8-4.8V49.7c0-1.1.4-2.2 1.1-3 .8-.7 1.9-1.1 3-1.1h70.9c2.7-.1 4.9 2.1 4.9 4.8z"
            className="st2"
        />
        <path
            d="M95.7 50.4v45.1c0 1.1-.4 2.2-1.1 3-.9 1.1-2.2 1.8-3.7 1.8h-71c-2.7 0-4.8-2.2-4.8-4.8V50.4c0-1.5.7-2.8 1.8-3.7.8-.7 1.9-1.1 3-1.1h70.9c2.7-.1 4.9 2.1 4.9 4.8z"
            className="st2"
        />
        <path
            d="M90.8 100.3H19.9c-2.7 0-4.8-2.2-4.8-4.8V50.4c0-2.7 2.2-4.8 4.8-4.8h70.9c2.7 0 4.8 2.2 4.8 4.8v45.1c0 2.6-2.1 4.8-4.8 4.8z"
            style={{
                fill: "none",
            }}
        />
        <path
            d="M90.8 100.3H19.9c-2.7 0-4.8-2.2-4.8-4.8V50.4c0-2.7 2.2-4.8 4.8-4.8h70.9c2.7 0 4.8 2.2 4.8 4.8v45.1c0 2.6-2.1 4.8-4.8 4.8z"
            className="st0"
        />
        <path
            d="M38.2 78.7H24.1c-1 0-1.8-.8-1.8-1.8v-8.1c0-1 .8-1.8 1.8-1.8h14.1c1 0 1.8.8 1.8 1.8V77c0 .9-.8 1.7-1.8 1.7z"
            style={{
                fill: "#e5cf89",
            }}
        />
        <path
            d="M93.2 98.4h-71c-2.7 0-4.8-2.2-4.8-4.8V48.5c0-1.1.4-2 .9-2.8-1.9.6-3.3 2.4-3.3 4.5v45.1c0 2.7 2.2 4.8 4.8 4.8h70.9c1.6 0 3-.8 3.9-2-.4.2-.9.3-1.4.3z"
            className="st2"
        />
        <path
            d="M85.9 91.8H69.3c-.4 0-.8.3-.8.8v.9c0 .4.3.8.8.8h16.6c.4 0 .8-.3.8-.8v-.9c0-.5-.4-.8-.8-.8zM65.3 91.8H40.2c-.4 0-.8.3-.8.8v.9c0 .4.3.8.8.8h25.1c.4 0 .8-.3.8-.8v-.9c0-.5-.3-.8-.8-.8z"
            className="st1"
        />
    </svg>
)
export default Payment
